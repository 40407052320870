<template>
  <div class="store">
    <div class="container mx-auto max-w-sm">
      <store-content :order="order"></store-content>
      <div class="px-4 py-2 my-2 flex justify-center w-full" style="bottom: 60px; z-index: 100">
        <button class="btn btn--primary btn--sm mr-8 ml-8" style="min-width: 200px" @click.prevent="onClickCreateGame">OYNA ÖDÜL KAZAN</button>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import StoreContent from "@/views/components/StoreContent"
import VFooter from "@/views/components/Footer";
import Feed from "@/mixins/Feed"
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {
    StoreContent,
    VFooter,
  },
  props: {
    order: String
  },
  mixins: [Feed],
  metaInfo:{
    title: 'Mağaza',
    meta: [
      { property: 'og:title', content:'Mağaza - Adam Asmaca Oyunu' },
      { property: 'description', content:"Büyük indirimler, koin paketleri, yeni can ve güçler seni oyun içerisindeki diğer oyunculara karşı avantajlı bir noktaya getirecek. Türkiye'nin Adam Asmaca Oyunu'nda ligiin en iyileri arasına girebilmen için ihtiyaç duyacağın her şey oyun mağazamızda. İster koin, ister mobil ödeme ister, kredi kartı ister Google Pay, istersen de Apple Pay ile alışverişini yapabilirsin." },
    ]
  },
  created() {
    this.feedAllEvent('view_store', null, 'engagement', null, 'page')
  },
  methods: {
    ...mapActions(["createGame", "checkOpenGame"]),
    ...mapGetters(["checkTicket", "hasImmortality"]),
    onClickCreateGame() {
      this.checkOpenGame().then(() => {
        if (this.checkTicket()) {
          if(this.$store.state.openGame) {
            this.$router.push({name: "game", params: {id: this.$store.state.openGame}, query: this.$route.query})
          }else{
            Promise.all([this.createGame({gameType: '1'})]).then(() => {
              this.$router.push({name: "game", params: {id: 'league'}, query: this.$route.query})
            })
          }
        } else {
          this.$modal.show("promo-modal", {promoType: "ticket"});
        }
      });
    }
  }
}
</script>